import React from 'react';
import InnerPageBanner from 'components/inner_page_banner';
import TermsOfService from 'components/terms_of_service';

const TermsPage = () => {
    return (
        <>
            <InnerPageBanner title={"Terms of Service"} />
            <TermsOfService />
        </>
    )
}

export default TermsPage