import React from 'react';
import { Link } from "gatsby";


const InnerPageBanner = ({title, breadcrumbs}) => {
    return (
        <>
        <div id="header-area-space" className="slider-area bg-light-primary"/>
        <section className="inner-page-banner bg-overlay pseudo-bg-dark-primary50 bg-common" data-bg-image="/img/slider/slider1-1.jpg">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <div className="breadcrumbs-area">
                    <h1>{title}</h1>
                    <ul>
                        <li>
                        {/* <Link to="/">Home</Link> */}
                            <a href="/" >Home</a>
                        </li>
                        {breadcrumbs && breadcrumbs.map(page => {
                            return <li>
                                <a href={page.link}>{page.title}</a>
                            </li>
                        })}
                        <li>{title}</li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default InnerPageBanner;