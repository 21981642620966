import React from 'react';

const TermsOfService = () => {
    return (
        <div className='container'>
            <br/>
            <br/>
            <div>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                Finstead Capital ACN 638 390 182 refers to the following Terms of Service,
                all applicable websites are referred to as the ‘Finstead Websites.' These
                Conditions of Use, together with any other terms, notices or disclaimers
                contained elsewhere on the Finstead Websites, govern the supply of
                material on, use of and access to the Finstead Websites. By accessing the
                Finstead Websites, you agree to be bound by these Conditions of Use and
                any modifications of them.
                </p>
                <h3 style={{ whiteSpace: "pre-wrap" }}>Information and accuracy</h3>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                Information provided on the Finstead websites:
                </p>
                <ul data-rte-list="default">
                <li>
                    <p className style={{ whiteSpace: "pre-wrap" }}>
                    is intended for Australian residents only and may not comply with
                    non-Australian laws;
                    </p>
                </li>
                <li>
                    <p className style={{ whiteSpace: "pre-wrap" }}>
                    is of a general nature and does not take into account your individual
                    circumstances, financial needs or objectives;
                    </p>
                </li>
                <li>
                    <p className style={{ whiteSpace: "pre-wrap" }}>
                    is not intended to be investment advice nor a recommendation or
                    statement of opinion about any particular financial product or class
                    of financial products. As a result, it should not be relied upon in
                    acquiring any product or service offered by Finstead; and
                    </p>
                </li>
                <li>
                    <p className style={{ whiteSpace: "pre-wrap" }}>
                    is provided in good faith and derived from sources believed to be
                    accurate and current at the date of publication.
                    </p>
                </li>
                </ul>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                Finstead does not guarantee the accuracy, adequacy or completeness of
                information on the Websites or that those websites are otherwise free from
                error, omission or other defect. Finstead reserves the right to change any
                information provided on Finstead Websites without notice.
                </p>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                Unless otherwise specified, all references to currency are to Australian
                dollars.
                </p>
                <h3 style={{ whiteSpace: "pre-wrap" }}>Your conduct</h3>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                You warrant that when accessing and using the Finstead Websites you will
                not use any website for any purpose that is unlawful or prohibited by
                these On-line Conditions of Use or any other term, notice or disclaimer
                appearing on the Finstead Websites.{" "}
                </p>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                You agree to not breach or circumvent, or attempt to breach or circumvent,
                any security measures on the Finstead Websites (including 'hacking' those
                websites) or attempt to obtain access to secure or member parts of the
                Finstead Websites without authorisation.
                </p>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                Finstead retains the right to monitor your use of the Finstead Websites
                and prevent further access at any time in its discretion.
                </p>
                <h3 style={{ whiteSpace: "pre-wrap" }}>Copyright and trade marks</h3>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                All material on the Finstead Websites is protected by copyright. You may
                only use this material for your own personal reference. You must not
                otherwise use, reproduce, publish, modify, distribute, link, frame,
                transmit in any form or by any means, electronic or mechanical, for any
                purpose, any of the material on the Finstead Websites, except with the
                prior written permission of Finstead
                </p>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                Trade marks used on the Finstead Websites are the property of Finstead or
                third parties with which Finstead has an association. You must not use a
                trade mark used on the Finstead Websites without the prior written consent
                of the owner of that trade mark.
                </p>
                <h3 style={{ whiteSpace: "pre-wrap" }}>Limitation of liability</h3>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                You agree that your use of the Finstead Websites and reliance on any
                information contained on those websites, is entirely at your own risk. To
                the extent permitted by law, Finstead excludes all liability for any loss
                or damage (including indirect or consequential loss or damage) you or any
                other person suffers in connection with your use of the Finstead Websites,
                including your reliance on any information contained on the Finstead
                Websites.
                </p>
                <h3 style={{ whiteSpace: "pre-wrap" }}>Privacy</h3>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                Your personal information will be collected and used by Finstead in
                accordance with its <a href="/privacy">Privacy Policy</a>.
                </p>
                <h3 style={{ whiteSpace: "pre-wrap" }}>Governing Law</h3>
                <p className style={{ whiteSpace: "pre-wrap" }}>
                These On-line Conditions of Use and other terms, notices and disclaimers
                appearing on the Finstead Australia Websites are governed by the law in
                force in the state of Victoria. You agree to the non-exclusive
                jurisdiction of the courts of NSW, Australia and any courts which may hear
                appeals from those courts in respect of any proceedings in connection with
                these online conditions of us
                </p>
            </div>
            <br/>
            <br/>
        </div>
    )
}

export default TermsOfService;